import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Location } from '@reach/router';
// import ProfilePic from '../components/profilepic';
import Img from 'gatsby-image';
// import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import {
      IoIosCalendar,
      IoLogoFacebook,
      IoLogoTwitter,
      IoLogoWhatsapp,
      IoLogoLinkedin,
      IoIosMail,
      IoIosLink,
    } from "react-icons/io";
import {
        EmailShareButton, 
        FacebookShareButton,
        WhatsappShareButton,
        LinkedinShareButton,
        TwitterShareButton,
        } from "react-share";

        

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    // console.log(this.props.data.allFile.edges.node.sourceInstanceName)
    // console.log(this.props.location);

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />

        <div className="w-full max-w-5xl rfs-mx-auto rfs-mt-32 rfs-px-4">
            
            <h1 className="font-bold leading-none rfs-text-6xl">
                {post.frontmatter.title}
            </h1>

            { (post.frontmatter.description) ? 
              <p className="max-w-2xl font-light text-left rfs-text-3xl">
                  {post.frontmatter.description}
              </p> : ''
            }

            { (post.fields.date != "Invalid date") ? 
                <p className="font-bold uppercase rfs-text-xs ">
                    {post.fields.date}
                </p> : ''
            }

            
            {/* <ProfilePic /> */}

            { (post.frontmatter.shareme == false) ? 
              '' : ( <div className="flex flex-row flex-wrap">
              <FacebookShareButton 
                url={this.props.location.href}
                quote={post.frontmatter.title}
                className="sharebutton"
                >
                  <IoLogoFacebook />
                  <span>Facebook</span>
                </FacebookShareButton>

                <TwitterShareButton 
                url={this.props.location.href}
                title={post.frontmatter.title}
                className="sharebutton"
                >
                  <IoLogoTwitter />
                  <span>Twitter</span>
                </TwitterShareButton>

                <WhatsappShareButton
                url={this.props.location.href}
                title={post.frontmatter.title}
                separator=":: "
                className="sharebutton"
                >
                  <IoLogoWhatsapp />
                  <span>WhatsApp</span>
                </WhatsappShareButton>

                <LinkedinShareButton
                source={this.props.location.href}
                title={post.frontmatter.title}
                className="sharebutton"
                >
                  <IoLogoLinkedin />
                  <span>LinkedIn</span>
                </LinkedinShareButton>

                <EmailShareButton
                url={this.props.location.href}
                subject={post.frontmatter.title}
                body="Ich habe hier einen interessanten Artikel gefunden: "
                separator=" - "
                className="sharebutton"
                >
                  <IoIosMail />
                  <span>Email</span>
                </EmailShareButton>
              
              </div>
              )}
        </div>        

        { (post.frontmatter.banner) ? (
          <Img
            className="banner-image sm:rfs-px-4"
            fluid={post.frontmatter.banner.childImageSharp.fluid}
            >
          </Img>
          ) : '' }


        <div className="flex flex-row flex-wrap justify-between w-full max-w-5xl rfs-mx-auto rfs-mt-12 sm:flex-nowrap ">
              <div className="w-full text-left rfs-px-4 rfs-mb-16 content sm:leading-relaxed hyphens-auto">
                  
                  <MDXRenderer>{post.body}</MDXRenderer>
              
              </div>

              {/* <aside className="px-4 text-lg sidebar sm:text-xl ">
                <MySidebar />
              </aside> */}
        </div>

        {/* { 
        (post.fields.sourceName === "blog") ? (
          <ul className="flex flex-row flex-no-wrap justify-between mt-8">
          <li className="">
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li className="text-right">
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul> ) : ''} */}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        description
        title
        banner {
                          childImageSharp {
                            sizes(maxWidth: 720) {
                              ...GatsbyImageSharpSizes
                            }
                            fluid(quality: 90, maxWidth: 720) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                    }
        # date(formatString: "DD. MMMM YYYY - HH:mm", locale: "de")
        shareme
      }
      fields {
        sourceName
        date(formatString: "DD. MMMM YYYY", locale: "de")
        changeDate(formatString: "DD.MM.YYYY - HH:mm", locale: "de")
      }
      body
    }
  }
`
