import PropTypes from "prop-types";
import React from "react";
import { motion, AnimatePresence } from 'framer-motion'
// import Headroom from 'react-headroom';

import Header from "./header";
// import Footer from "./footer";

const duration = 0.1
const delay = 0

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}
function Layout({ children, bg }) {

  return (
    // <div className="flex flex-col h-screen min-h-fill-available ios-safe">
      <div className="layout">
      {/* <div className={`flex flex-col min-h-screen h-screen ios-safe`}> */}
      
      {/* <Headroom> */}
      <Header />
      {/* </Headroom> */}

      {/* <AnimatePresence>
        <motion.main
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        > */}
        
        <main>
        {children}
        </main>
        {/* </motion.main>
        </AnimatePresence> */}

      {/* <Footer /> */}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
};

export default Layout;
